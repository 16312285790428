import React from "react";
import { Link } from "gatsby";
import JournalCard from "./JournalCard";

const BlogNext = ({
  posts,
  title = `More from the Blog`,
  background = false,
  cols = 4,
  container = 10,
}) => {
  return (
    <aside
      className={`blog-more section pt-4 ${
        background ? `bg-${background}` : ""
      }`}
    >
      {title && <h2 className="text-center my-5">{title}</h2>}

      <div className="container-fluid negative-margin ">
        <div
          className={`row justify-content-center ${
            background ? `bg-${background}` : "bg-light"
          } mt-5`}
        >
          <div className={`col-lg-${container} py-5`}>
            <div className="row negative-margin_inner justify-content-center contained-xl">
              {posts &&
                posts.map(({ node: post }, index) => (
                  <div key={post.id} className={`col-md-6 col-lg-${cols} mb-4`}>
                    <JournalCard
                      featured_media={post.frontmatter.image}
                      title={post.frontmatter.title}
                      date={post.frontmatter.date}
                      excerpt={post.frontmatter.excerpt}
                      slug={post.fields.slug}
                      readtime={post.timeToRead}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div
          className={`row justify-content-center pb-5 ${
            background ? `bg-${background}` : "bg-light"
          }`}
        >
          <Link
            to="/blog/"
            className={`btn btn-outline-${
              background === "primary" ? `white` : "primary"
            }`}
          >
            More News
          </Link>
        </div>
      </div>
    </aside>
  );
};

export default BlogNext;
