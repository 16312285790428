import React from "react";
import Image from "../Hacks/Image";

const BlogFooter = ({ category, author }) => (
  <React.Fragment>
    {author ? (
      <section className="journal_content__text journal_content__author container py-5">
        <div className="news-inner row justify-content-center contained-xl">
          <div className="col-8 col-md-6 col-lg-4 mb-4">
            <Image
              image={author.featuredimage}
              alt={author.title}
            />
          </div>
          <div className="col-12 col-md-6 align-self-center">
            <address className="author">
              <h3 className="text-md-left mb-4">
                <div className="socialshare">
                  {author.facebook && (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={author.facebook}
                      className="text-gray"
                    >
                      <i className="fab ml-1 fa-facebook"></i> <span className="sr-only">Facebook</span>
                    </a>
                  )}
                  {author.twitter && (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={author.twitter}
                      className="text-gray"
                    >
                      <i className="fab ml-1 fa-twitter"></i> <span className="sr-only">Twitter</span>
                    </a>
                  )}
                  {author.linkedin && (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={author.linkedin}
                      className="text-gray"
                    >
                      <i className="fab ml-1 fa-linkedin"></i> <span className="sr-only">LinkedIn</span>
                    </a>
                  )}
                </div>
                <div className="small">Written by</div>
                {author.name}
                {author.title && <div className="small">{author.title}</div>}
              </h3>
              <p>{author.excerpt}</p>
            </address>
          </div>
        </div>
      </section>
    ) : (
      false
    )}
  </React.Fragment>
);

export default BlogFooter;
